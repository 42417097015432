import Swal from "sweetalert2";

// Regions where this application could be used and for managing region specific data like phone numbers api endpoint alias etc.
export const REGIONS = [
    {
        id: 0,
        name: "New Zealand",
        countryCode: "NZ",
        locale: "en-NZ",
    },
    {
        id: 1,
        name: "Turkey",
        countryCode: "TR",
        locale: "tr-TR",
    },
    {
        id: 2,
        name: "Ireland",
        countryCode: "IE",
        locale: "en-IE",
    },
];

export const DEFAULT_REGION = REGIONS[2];

// Aliases used through the application.
export const CLASS_ALIAS = "Year";
export const DIVISION_ALIAS = "Room";
export const COMMON_CLASS_ALIAS = "Year group";

// API config.
// export const BASE_URL = "http://localhost:4000"; // Local
// Dev / Staging 
// export const BASE_URL = "https://api.beta.cybersmarties.co.nz"; // Staging
// export const BASE_URL = "https://api.cybersmarties.co.nz"; // Prod NZ
export const BASE_URL = "https://api.cybersmarties.ie"; // Prod IE
// export const BASE_URL = "https://cs-server-app.onrender.com";     // Render temp deployed

export const ANALYTICS_URL = "https://schoolanalytics.cybersmarties.ie";

// Sweetalert2 config - use this variable to file the alert.
export const Alert = Swal.mixin({
    heightAuto: false,
});
export const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
    },
});

// Token expiration after hours and minutes.
export const TOKEN_EXPIRE_IN_HOURS = 1;
export const TOKEN_EXPIRE_IN_MINUTES = 0;
