import "./App.css";
import Navbar from "./components/UI/Navbar";
import LandingPage from "./components/LandingPage";
import Login from "./components/Login";
import RegisterSchool from "./components/Register/RegisterSchool";
import ManageTeacher from "./components/ManageTeacher/ManageTeacher";
import ManageClassDivision from "./components/ManageClassDivision/ManageClassDivision";
import ManageStudent from "./components/ManageStudent/ManageStudent";
import ManageRoom from "./components/ManageRoom/ManageRoom";
import AnalyticsPage from "./components/Analytics/AnalyticsPage";
import StyleClassContext from "./store/StylesContext";
import { MainErrorPage } from "./components/UI/Errors";
import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
    useLoaderData,
    useSubmit,
} from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Analytics } from "@vercel/analytics/react";
import Logout, { action as logoutAction } from "./components/Logout";
import { checkAuthLoader, getAuthToken, getTokenExpiration } from "./util/auth";
import { queryClient } from "./util/http";

// Root Layout
const Main = () => {
    const [styleClass, setStyleClass] = useState("");
    const submit = useSubmit();
    const token = useLoaderData();

    // Token expiration logic.
    useEffect(() => {
        if (!token) return;
        const tokenExpirationDuration = getTokenExpiration();
        if (tokenExpirationDuration < 0) {
            // console.log("Token expired!");
            submit(null, { action: "/logout", method: "POST" });
            return;
        }
        setTimeout(() => {
            submit(null, { action: "/logout", method: "POST" });
        }, tokenExpirationDuration);
    }, [token, submit]);

    return (
        <>
            <header>
                <Navbar />
            </header>
            <main
                className={`bg flex-fill ${styleClass}`}
                style={{ marginTop: "68px", minWidth: "100%" }}
            >
                <div className="container h-100">
                    <StyleClassContext.Provider value={setStyleClass}>
                        <Outlet />
                    </StyleClassContext.Provider>
                </div>
            </main>
            <Analytics />
        </>
    );
};

const router = createBrowserRouter([
    {
        id: "root",
        path: "/",
        element: <Main />,
        errorElement: <MainErrorPage />,
        loader: getAuthToken,
        children: [
            { index: true, element: <LandingPage /> },
            { path: "login/", element: <Login /> },
            { path: "register/", element: <RegisterSchool /> },
            {
                id: "protected",
                path: "",
                loader: checkAuthLoader,
                children: [
                    {
                        path: "logout/",
                        element: <Logout />,
                        action: logoutAction,
                    },
                    { path: "teachers/", element: <ManageTeacher /> },
                    { path: "classes/", element: <ManageClassDivision /> },
                    { path: "students/", element: <ManageStudent /> },
                    {
                        path: "classes/:classId/divisions/:divisionId",
                        element: <ManageRoom />,
                    },
                    { path: "analytics/", element: <AnalyticsPage /> },
                ],
            },
        ],
    },
]);

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
        </QueryClientProvider>
    );
}

export default App;
